import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import Seo from '../../components/Seo'
import StructuredData from '../../components/structured-data'

function SAPostings({ data }) {
  const { wpPage } = data

  return (
    <Layout>
      <Seo post={wpPage} />
      <StructuredData pageType="webPage" />
      <div className="mb-10 lg:mb-16 py-10 px-5 lg:py-24 relative text-white">
        <div className="container relative z-30">
          <h1 className="text-white">Careers at Liquid Web San Antonio</h1>
          <div role="doc-subtitle" className="py-1 text-3xl font-semibold">
            We&apos;re Looking for People Just Like You
          </div>
        </div>
        <div
          className="absolute w-full h-full top-0 left-0 z-20"
          style={{
            backgroundColor: `#2e3c4b`,
          }}
        ></div>
      </div>
      <div className="container">
        <h3>Current Job Openings</h3>
        <div className="py-3 text-lg uppercase font-semibold">
          FOR SAN ANTONIO, TEXAS
        </div>
        <div className="pb-10">
          <iframe
            style={{ height: '350px' }}
            name="resumator-job-frame"
            id="resumator-job-frame"
            src="//liquidweb.applytojob.com/apply/jobs/?city=San+Antonio"
            width="100%"
          ></iframe>
        </div>
      </div>
    </Layout>
  )
}

SAPostings.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default SAPostings

export const query = graphql`
  {
    wpPage(databaseId: { eq: 5727 }) {
      title
      date
      uri
      modified
      chatDepartment
      seo {
        ...postSeoFields
      }
    }
  }
`
